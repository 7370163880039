.content.content-about {
  .about-list {
    margin-left: -2px;

    .about-item {
      position: relative;
      padding: 10px 0;

      .text {
        padding-left: 20px;
      }
    }
  }

  a.text-value-url {
    color: $site-color;

    &:hover {
      color: darken($site-color, 10%);
    }
  }

  .dot {
    position: absolute;
    top: 50%;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;
    content: ' ';
    border-radius: 50%;

    &.icon {
      font-size: 12px;
      line-height: $icon-height;
      width: $icon-height;
      height: $icon-height;
      margin-top: -$icon-height / 2;

      //color: #fff;
      margin-left: -$icon-height / 2;

      //text-align: center;
      padding-left: 2px;

      //color: rgba(0, 0, 0, .8);
      color: rgba(255, 255, 255, 0.6);
    }

    &.dot-0 {
      background: #1abc9c;
    }

    &.dot-1 {
      background: #3498db;
    }

    &.dot-2 {
      background: #9b59b6;
    }

    &.dot-3 {
      background: #e67e22;
    }

    &.dot-4 {
      background: #e74c3c;
    }
  }
}

@media screen and (min-width: 768px) {
  .content.content-about {
    width: $about-width;
  }
}

@media screen and (max-width: 767px) {
  .content.content-about {
    .about-list {
      margin-left: 0;
      border-left: 4px solid #f9f9f9;
      .dot.icon {
        margin-left: -12px;
      }
    }
  }
}

