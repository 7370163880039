.content.content-link {
  .link-list {
    .link-item {
      position: relative;
      margin-left: -23px;
      padding: 8px 0;
      a {
        display: block;
        color: #444;
        .avatar,
        .wrap-info {
          display: inline-block;
          vertical-align: middle;
        }
        .avatar {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          &:hover {
            opacity: .8;
          }
        }
        .wrap-info {
          //word-wrap: break-word;
          //word-break: normal;
          line-height: 1.3em;
          .name {
            font-weight: bold;
            &:hover {
              color: $site-color;
            }
          }
          .info {
            font-size: 13px;
            color: #999;
            min-width: 240px;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .content.content-link {
    hr {
      display: none;
      height: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .content.content-link {
    width: 100%;
    .link-list {
      .link-item {
        position: relative;
        margin-left: 0;
        padding: 8px 0 8px 10px;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .content.content-link {
    width: 100%;

    .link-list {
      .link-item {
        position: relative;
        margin-left: 0;
        padding: 8px 0 8px 10px;
      }
    }
  }
}
