.item-category-name {
  position: relative;
  margin-top: 40px;
  padding-left: 20px;
  .category-count {
    font-family: 'calligraffittiregular';
    font-size: 16px;
    font-weight: bold;
  }
}
