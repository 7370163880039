.pagination {
  .page-nav {
    .page-number {
      font-family: 'calligraffittiregular';
      font-size: 15px;
      font-weight: bolder;
      line-height: 33px;
      display: inline-block;
      width: $pagination-item-size;
      height: $pagination-item-size;
      margin: auto 6px;
      text-align: center;
      color: $pagination-color;
      border-radius: 50%;
      &:hover,
      &.current {
        color: $pagination-color;
        background: $pagination-bg-color;
      }
    }
    .space {
      letter-spacing: 2px;
    }
    .extend {
      font-size: 20px;
      line-height: 25px;
      display: inline-block;
      width: $pagination-item-size;
      height: $pagination-item-size;
      text-align: center;
      color: $pagination-color;
      border-radius: 50%;
      transition-duration: .5s;
      transition-propety: background-color;
      &:hover {
        color: $pagination-color;
        background: $pagination-bg-color;
      }
    }
  }
}
