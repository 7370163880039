.item-title {
  position: relative;
  margin-top: 40px;
  padding-left: 20px;
  &:before {
    position: absolute;
    top: 50%;
    left: -2px;
    width: 10px;
    height: 10px;
    margin-top: -9px;
    margin-left: -5px;
    content: ' ';
    border-radius: 50%;
  }
  &.item-title-0:before {
    background: #1abc9c;
  }
  &.item-title-1:before {
    background: #3498db;
  }
  &.item-title-2:before {
    background: #9b59b6;
  }
  &.item-title-3:before {
    background: #e67e22;
  }
  &.item-title-4:before {
    background: #e74c3c;
  }
}
