.post-header {
  margin: 0 auto;
  padding-top: 20px;
  &.LEFT {
    width: $post-width - 40px;
    border-left: 4px solid #f0f0f0;
  }
  &.CENTER {
    width: 4px;
    background: #f0f0f0;
  }
  .toolbox {
    margin-top: -40px;
    margin-left: -18px;
    background: #fff;
    transition-duration: 0.5s;
    transition-propety: transform;
    &:hover {
      transform: translate(0, 30px);
    }
    .toolbox-entry {
      .icon-angle-down {
        margin-top: 16px;
        display: inline-block;
        line-height: 0;
        font-size: 22px;
        border-radius: 50%;
      }
      .toolbox-entry-text {
        display: none;
      }
    }
  }
}
.content.content-post {
  border-left: none;
  margin: 50px auto;
  &.CENTER {
    .article-header {
      text-align: center;
    }
  }
  .article-header {
    margin-bottom: 40px;
    .post-title {
      font-size: 32px;
      font-weight: normal;
      margin: 0 0 12px;
      color: $title-color;
    }
    .article-meta {
      font-size: 12px;
      margin-top: 8px;
      margin-bottom: 30px;
      color: #999;
      a {
        color: #999;
      }
      > span > * {
        vertical-align: middle;
      }
      i {
        display: inline-block;
        margin: 0 -4px 0 4px;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .content.content-post {
    width: $post-width;
    margin-top: 60px;
  }
}
@media screen and (max-width: 767px) {
  .post-header {
    display: none;
  }
  .content.content-post {
    .article-content,
    .post-title {
      padding-right: 10px;
      padding-left: 10px;
    }
    .article-header .post-title {
      font-size: 24px;
    }
  }
  .content.content-archive {
    .archive-body {
      border-left: none;
      .item-title:before,
      .item-post:before {
        display: none;
      }
      .item-year,
      .item-post {
        padding-left: 0;
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .content.content-post {
    width: 95%;
  }
}
