.item-year {
  position: relative;
  margin-top: 40px;
  padding-left: 20px;
  a.text-year {
    font-family: 'calligraffittiregular';
    font-size: 20px;
    font-weight: bold;
    font-weight: bold;
    color: #222;
  }
}
