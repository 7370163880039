@font-face {
  font-family: 'fontello';
  src: url('/fonts/fontello.eot?58336539');
  src: url('/fonts/fontello.eot?58336539#iefix') format('embedded-opentype'),
       url('/fonts/fontello.woff2?58336539') format('woff2'),
       url('/fonts/fontello.woff?58336539') format('woff'),
       url('/fonts/fontello.ttf?58336539') format('truetype'),
       url('/fonts/fontello.svg?58336539#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?58336539#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-feather:before { content: '\e800'; } /* '' */
.icon-cc:before { content: '\e802'; } /* '' */
.icon-long:before { content: '\e806'; } /* '' */
.icon-angle-left:before { content: '\e807'; } /* '' */
.icon-text:before { content: '\e808'; } /* '' */
.icon-hu:before { content: '\e809'; } /* '' */
.icon-weibo:before { content: '\e80a'; } /* '' */
.icon-angle-down:before { content: '\e80b'; } /* '' */
.icon-archive:before { content: '\e80c'; } /* '' */
.icon-search:before { content: '\e80d'; } /* '' */
.icon-rss-2:before { content: '\e80e'; } /* '' */
.icon-heart:before { content: '\e80f'; } /* '' */
.icon-zhu:before { content: '\e810'; } /* '' */
.icon-user-1:before { content: '\e811'; } /* '' */
.icon-calendar-1:before { content: '\e812'; } /* '' */
.icon-ma:before { content: '\e813'; } /* '' */
.icon-box:before { content: '\e814'; } /* '' */
.icon-home:before { content: '\e815'; } /* '' */
.icon-shu:before { content: '\e816'; } /* '' */
.icon-calendar:before { content: '\e817'; } /* '' */
.icon-yang:before { content: '\e818'; } /* '' */
.icon-user:before { content: '\e819'; } /* '' */
.icon-info-circled-1:before { content: '\e81a'; } /* '' */
.icon-lsit:before { content: '\e81b'; } /* '' */
.icon-rss:before { content: '\e81c'; } /* '' */
.icon-info:before { content: '\e81d'; } /* '' */
.icon-wechat:before { content: '\e81e'; } /* '' */
.icon-comment:before { content: '\e81f'; } /* '' */
.icon-she:before { content: '\e820'; } /* '' */
.icon-info-with-circle:before { content: '\e821'; } /* '' */
.icon-niu:before { content: '\e822'; } /* '' */
.icon-mail:before { content: '\e823'; } /* '' */
.icon-list:before { content: '\e824'; } /* '' */
.icon-gou:before { content: '\e825'; } /* '' */
.icon-tu:before { content: '\e826'; } /* '' */
.icon-twitter:before { content: '\e827'; } /* '' */
.icon-location:before { content: '\e828'; } /* '' */
.icon-hou:before { content: '\e829'; } /* '' */
.icon-qq:before { content: '\e82a'; } /* '' */
.icon-tag:before { content: '\e82b'; } /* '' */
.icon-angle-right:before { content: '\e82c'; } /* '' */
.icon-github:before { content: '\e82d'; } /* '' */
.icon-angle-up:before { content: '\e82e'; } /* '' */
.icon-ji:before { content: '\e82f'; } /* '' */
