@media screen and (min-width: 768px) {
  .fexo-comments {
    &.comments-post {
      width: $post-width;
    }
    &.comments-about {
      width: $about-width;
    }
    &.comments-link {
      width: 500px
    }
    margin: 0 auto 60px;
  }
}
@media screen and (max-width: 767px) {
  .fexo-comments {
    padding: 10px;
  }
}
