 body, .smooth-container { scroll-behavior: smooth }
html,
body {
  background: #fff;
  font-family: PingFangSC-Regular,'Roboto', Verdana, "Open Sans", "Helvetica Neue", "Helvetica", "Hiragino Sans GB", "Microsoft YaHei", "Source Han Sans CN", "WenQuanYi Micro Hei", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  min-height: 100%;
}
code,
pre,
samp {
  font-family: PingFangSC-Regular, 'Roboto', Verdana, "Open Sans", "Helvetica Neue", "Helvetica", "Hiragino Sans GB", "Microsoft YaHei", "Source Han Sans CN", "WenQuanYi Micro Hei", Arial, sans-serif;
}
*,
 {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

ul {
  line-height: 1.8em;
  padding: 0;
  list-style: none;
  li {
    list-style: none;
  }
}

.text-center {
  text-align: center;
}
@media screen and (max-width: 767px) {
  html,
  body {
    overflow-x: hidden;
  }
}
