$site-color: #1abc9c;
$link-color: $site-color;
$link-hover-color: $site-color;

$tag-item-height: 30px;
$tag-title-height: 40px;

$category-item-height: 30px;
$category-title-height: 40px;

$post-width: 760px;
$article-color: #555;
$article-font-size: 15px;

$code-color: #555;
$code-font-size: 14px;

$title-color: #000;
$icon-height: 20px;

$pagination-item-size: 28px;
$pagination-color: #444;
$pagination-bg-color: #f0f0f0;

$toc-step: 12px;
$hint-height: 32px;

$about-width: 600px;
$search-width: 600px;
$project-width: 600px;
$input-search-height: 36px;
